/** @jsxImportSource @emotion/react */

import { MouseEvent } from 'react';

import { css } from '@emotion/react';
import { Link, Text } from '@zapier/design-system';

import { MEDIA_MIN_TABLET } from './constants';

const Styles = {
  loginLink: css`
    color: inherit !important;
    text-decoration: none !important;
    transition: all 0.2s ease-in-out !important;
    white-space: nowrap !important;
  `,
  root: (isLoading: boolean) => css`
    align-items: center;
    color: inherit;
    display: ${isLoading ? 'none' : 'flex'};
    justify-content: space-around;
    /* Prefer visibility to display to keep the header height. */
    ${MEDIA_MIN_TABLET} {
      justify-content: initial;
      gap: 16px;
    }
  `,
};

type Props = {
  isLoading: boolean;
  onClickLogIn?: (event: MouseEvent) => void;
  urlLogIn: string;
};

export default function AuthButtons({
  isLoading,
  onClickLogIn,
  urlLogIn,
}: Props) {
  return (
    <nav css={Styles.root(isLoading)}>
      <Link
        css={Styles.loginLink}
        href={urlLogIn}
        onClick={onClickLogIn}
        target="_blank"
      >
        <Text color="inherit" type="paragraph3">
          Log in
        </Text>
      </Link>
    </nav>
  );
}
