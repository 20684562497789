import { Colors } from '@zapier/theme';

const THEME = {
  light: {
    primary: '#fffdf9',
    secondary: Colors.white,
  },
  dark: {
    primary: Colors.deepSpace,
    secondary: Colors.deepSpace,
  },
};

export { THEME };
