/** @jsxImportSource @emotion/react */

import { usePathname } from 'next/navigation';

import { css } from '@emotion/react';
import { Colors, Link, Text } from '@zapier/design-system';

import { MEDIA_MIN_DESKTOP } from './constants';

const Styles = {
  link: ({
    isActive,
    isLightTheme,
  }: {
    isActive: boolean;
    isLightTheme: boolean;
  }) => css`
    color: inherit !important;
    display: block !important;
    padding: 15px 40px !important;
    text-decoration: none !important;
    width: 100% !important;
    ${MEDIA_MIN_DESKTOP} {
      border-bottom: 4px solid
        ${isActive
          ? isLightTheme
            ? Colors.zapier
            : Colors.neutral100
          : 'transparent'} !important;
      margin: 0px 17px !important;
      padding: 21px 3px 17px !important;
      width: initial !important;
      :hover {
        border-bottom-color: ${isLightTheme
          ? Colors.zapier
          : Colors.neutral100} !important;
        color: inherit !important;
      }
    }
  `,
  root: css`
    display: flex;
    list-style: none;
    :hover {
      background-color: ${Colors.blue10};
      color: ${Colors.blue};
    }
    ${MEDIA_MIN_DESKTOP} {
      display: inline-flex;
      :hover {
        background-color: initial;
        color: inherit;
      }
    }
  `,
};

type NavLink = {
  href: string;
  isVisible: boolean;
  label: string;
};

type Props = {
  isLightTheme: boolean;
  link: NavLink;
  onClick?: () => void;
};

const defaultProps = {
  onClick: () => undefined,
};

export default function NavLinkItem({
  isLightTheme,
  link,
  onClick = defaultProps.onClick,
}: Props) {
  const pathname = usePathname();
  return (
    <li key={link.label} css={Styles.root}>
      <Link
        css={Styles.link({
          isActive: link.href === pathname,
          isLightTheme,
        })}
        href={link.href}
        onClick={() => onClick()}
      >
        <Text
          color={link.isVisible ? 'inherit' : 'transparent'}
          type="paragraph3"
        >
          {link.label}
        </Text>
      </Link>
    </li>
  );
}
