/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Colors } from '@zapier/design-system';

import { MEDIA_MIN_DESKTOP } from './constants';
import NavLinkItem from './NavLinkItem';

const Styles = {
  list: css`
    margin: 0px;
    padding: 0px;
  `,
  root: css`
    border-top: 1px solid ${Colors.neutral400};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 30px 0px;
    justify-content: space-between;
    margin: 0px;
    ${MEDIA_MIN_DESKTOP} {
      background-color: initial;
      border: initial;
      box-shadow: initial;
      display: flex;
      flex: 1;
    }
  `,
};

type NavLink = {
  href: string;
  isVisible: boolean;
  label: string;
};

type Props = {
  isAuthed: boolean;
  isLightTheme: boolean;
  linksLeft: NavLink[];
  linksRight: NavLink[];
  onClickLink?: (link: NavLink) => void;
};

const defaultProps = {
  linksLeft: [],
  onClickLink: () => undefined,
};

export default function NavLinks({
  isAuthed,
  isLightTheme,
  linksLeft = defaultProps.linksLeft,
  linksRight,
  onClickLink = defaultProps.onClickLink,
}: Props) {
  return (
    <>
      {isAuthed && (
        <nav css={Styles.root}>
          <ul css={Styles.list}>
            {linksLeft.map((link) => (
              <NavLinkItem
                key={link.href}
                isLightTheme={isLightTheme}
                link={link}
                onClick={() => onClickLink(link)}
              />
            ))}
          </ul>
          <ul css={Styles.list}>
            {linksRight.map((link) => (
              <NavLinkItem
                key={link.href}
                isLightTheme={isLightTheme}
                link={link}
                onClick={() => onClickLink(link)}
              />
            ))}
          </ul>
        </nav>
      )}
    </>
  );
}
