/** @jsxImportSource @emotion/react */
'use client';

import { PropsWithChildren } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import { css } from '@emotion/react';
import { CurrentAccountIdProvider } from '@zapier/identity';

import { isDatadog } from '@/utils';

import {
  initializeDatadogRum,
  initializeSentry,
  nextJsReportWebVitals,
  TrackRouting,
} from '../observability';

import Header from './Header';

import 'react-toastify/dist/ReactToastify.css';

import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const shouldInitSentry = !isDatadog();
if (shouldInitSentry) {
  initializeSentry({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '',
    commitHash: process.env.NEXT_PUBLIC_COMMIT_SHA || '',
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV || '',
  });
}

// The following Split SDK configuration is for localhost development only.
// Add the SPLIT_SDK_API_KEY environment variable to your .env or Vercel project settings.
// And add a treatment via the Split.io dashboard to see the feature flag in action.
const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'localhost' || process.env.SPLIT_SDK_API_KEY,
    key: 'user_id',
  },
  features: {
    SayHello: 'on',
  },
};

// Measure and report web vitals that Next.js tracks to Datadog.
// See: https://nextjs.org/docs/advanced-features/measuring-performance
initializeDatadogRum();

const Styles = {
  root: css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  `,
  main: css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow: auto;
    height: calc(100vh - 69px);
  `,
};

export const reportWebVitals = nextJsReportWebVitals;

export default function App({ children }: PropsWithChildren) {
  return (
    <CurrentAccountIdProvider>
      <TrackRouting>
        <SplitFactoryProvider config={sdkConfig}>
          <QueryClientProvider client={queryClient}>
            <main>
              <Header />
              <main css={Styles.main}>
                {children}
                <ToastContainer
                  autoClose={false}
                  closeOnClick={false}
                  theme="colored"
                  transition={Slide}
                />
              </main>
            </main>
          </QueryClientProvider>
        </SplitFactoryProvider>
      </TrackRouting>
    </CurrentAccountIdProvider>
  );
}
