/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Avatar,
  Colors,
  FloatingBox,
  Menu,
  MenuItem,
} from '@zapier/design-system';

const Styles = {
  avatarButton: css`
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
  `,
  divider: css`
    background-color: ${Colors.neutral300};
    border: 0px;
    height: 1px;
    margin: 5px 0px;
    width: 100%;
  `,
  menuWrapper: css`
    padding: 10px;
  `,
  separator: css`
    border-top: 1px solid ${Colors.neutral300};
  `,
};

type Props = {
  isOpen: boolean;
  onClickLogOut?: () => void;
  onToggle: () => void;
  urlLogOut: string;
  user: {
    name: string;
    photoUrl: string;
  };
};

const UserDropdown = ({
  isOpen,
  onClickLogOut,
  onToggle,
  urlLogOut,
  user,
}: Props) => {
  return (
    <nav>
      <button css={Styles.avatarButton} onClick={() => onToggle()}>
        <Avatar
          isSelected={isOpen}
          name={user.name}
          size={40}
          url={user.photoUrl}
        />
      </button>
      {isOpen && (
        <FloatingBox
          align="right"
          hasWhiteBackground={true}
          minWidth="198px"
          onClickOutside={(event) => {
            event.stopPropagation();
            onToggle();
          }}
          position="south"
        >
          <div css={Styles.menuWrapper}>
            <Menu ariaLabel="User options">
              <MenuItem href={urlLogOut} onClick={onClickLogOut}>
                Log out
              </MenuItem>
            </Menu>
          </div>
        </FloatingBox>
      )}
    </nav>
  );
};

export default UserDropdown;
